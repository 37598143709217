#root {
  height: 100%;
}
.App {
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url("images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  color: #ffffff;
  background-color: #00000050;
  font-family: "Space Grotesk";
}

.app-header {
  width: 100%;
  height: 80px;
  padding-top: 30px;
  display: flex;
}
.header-logo {
  width: 30%;
}
.header-logo img{
  height: 40px;
}
.header-menu {
  display: flex;
  margin: 0 auto;
  font-size: 16px;
}
.header-menu-item {
  max-width: 200px;
  min-width: 150px;
}

.app-container {
  display: flex;
}

.faucet-form {
  max-width: 800px;
  width: 100%;
  margin: auto;
  margin-top: 100px;
}
.input-container {
  display: flex;
}
.faucet-form p{
  font-size: 74px;
  font-weight: 500;
  line-height: 85px;
}
.faucet-form img {
  height: 32px;
  margin-right: 5px;
}
.form-control, button {
  font-size: 13px !important;
  min-width: 110px;
}
.header-menu-item a {
  text-decoration: none;
  color: white;
}
.header-menu-item a:hover {
  color: #1FB0FF;
}
.address-input {
  border: 1px solid #1FB0FF;
  background: #000000;
  padding: 10px 20px;
  color: white;
  border-radius: 8px;
  width: 75%;
  margin-right: 10px;
}
.faucet-btc-btn {
  background-color: #1FB0FF;
  border: 1px solid #1FB0FF;
  font-size: 20px;
  min-width: 150px;
  font-weight: 500;
  color: white;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
}
.faucet-btc-btn:hover {
  background: #1FB0FF;
  border: 1px solid #1FB0FF;
}

.add-metamask button{
  border: 1px solid #1FB0FF;
  border-radius: 8px;
  margin-top: -10px;
  background: transparent;
  color: #1FB0FF;
  padding: 5px 15px;
  font-weight: bolder;
}
.add-metamask button:hover {
  color: white;
}
.app-footer {
  width: max-content;
  display: flex;
  margin: 50px auto;
}
.app-footer-item {
  width: 354px;
  height: 230px;
  margin: 20px;
  text-align: left;
  padding: 30px 80px 20px 20px;
  background-image: url("images/footer-background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.app-footer-item h3{
  font-size: 24px;
}
.app-footer-item p{
  font-size: 16px;
}
.app-footer-item button{
  background-color: #1FB0FF;
  border: none;
  padding: 6px 16px;
  font-size: 18px;
}
.app-footer-item button img{
  margin-left: 20px;
}
.tx-alert {
  border: 1px solid #1FB0FF;
  width: 100%;
  background: black;
  max-width: 600px;
  margin: 20px auto 0 auto;
  font-size: 14px;
  text-align: left;
  padding: 10px;
  border-radius: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tx-alert a{
  color: #1FB0FF;
}
@media only screen and (max-width: 600px) {
  .App {
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: max-content;
    padding-bottom: 50px;
  }
  .input-container {
    display: block;
  }
  .address-input {
    margin-right: 0;
  }
  .input-container button span{
    font-size: 18px;
  }
  .input-container button{
    margin-top: 10px;
    width: 50%;

  }
  .app-header {
    padding-top: 20px;
    padding-left: 20px;
  }
  .header-menu {
    display: none;
  }
  .app-footer {
    display: block;
  }
  .faucet-form {
    margin-top: 50px;
  }
  .faucet-form p {
    font-size: 48px;
  }
  .faucet-form img {
    display: none;
  }
  .tx-alert {
    width: 85%;
  }
  .faucet-btc-btn {
    height: 35px;
  }
}
